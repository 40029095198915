import { Col, Container, Navbar, Row } from "react-bootstrap"
import { Discord, Github, Twitter, Facebook, Linkedin } from 'react-bootstrap-icons'
import Navigation from "./Navigation"

export const Header = () => (
    <header>
        <Navbar variant="dark" fixed="top" className="text-light header p-0">
            <Container>
                <Row>
                    <Navigation />
                </Row>
            </Container>
        </Navbar>
    </header>
)

export const Footer = () => {
    let links = [
        {
            name: 'Discord',
            url: 'https://discord.gg/CZSJEVf',
            icon: <Discord />
        },
        {
            name: 'GitHub',
            url: 'https://github.com/FallenMoonNetwork',
            icon: <Github />
        },
        {
            name: 'X (Twitter)',
            url: 'https://twitter.com/thefallenmoon',
            icon: <Twitter />
        },
        {
            name: 'Facebook',
            url: 'https://facebook.com/fallenmoonllc',
            icon: <Facebook />
        },
        {
            name: 'LinkedIn',
            url: 'https://linkedin.com/company/fallenmoon',
            icon: <Linkedin />
        }
    ];

    return (
        <footer className="bg-blue text-light p-3">
            <Container className="text-center">
                <Row className="justify-content-center mb-3 g-5 g-md-0">
                    {links.map((link) => (
                        <Col xs={1} key={link.name}>
                            <a className="text-white fs-2" href={link.url} target="_blank" title={link.name} rel="noreferrer">
                                {link.icon}
                            </a>
                        </Col>
                    ))}
                </Row>
                <hr className="w-50 mx-auto" />
                <Row>
                    <Col>
                        <span className="fs-6">
                            &copy;{new Date().getFullYear()} Fallen Moon, LLC. All rights reserved.<br />
                            All logos, names, and images belong to their respective owners.
                        </span>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}