import { Container, Row, Col } from "react-bootstrap";

const softwareIcon = require('../assets/images/software-icon.png');
const websiteIcon = require('../assets/images/website-icon.png');
const businessIcon = require('../assets/images/business-icon.png');

const HomePage = () => {
    const HeaderImage = () => (
        <Container fluid className="header-img g-0">
            <div className="backdrop text-light">
                <Row className="justify-content-center center-content header-text dtl-nobel">
                    <Col xs={12} lg={9} className="text-center">
                        <h1 className="display-5">Dedicated Software Solutions</h1>
                        <span className="fs-3">
                            With over a decade of combined experience, from desktop applications
                            to web services and more, check out what we can do for you.
                        </span>
                    </Col>
                </Row>
            </div>
        </Container>
    )

    const Services = () => (
        <Container className="services">
            <div className="d-none d-md-block">
                <Row className="py-5">
                    <Col lg={4} className="text-center mt-4">
                        <img src={softwareIcon} alt="Software Development" height={214} />
                    </Col>
                    <Col lg={8} className="pt-5">
                        <h2 className="dtl-nobel">Software Development</h2>
                        <p>
                            We offer a comprehensive range of software development services tailored to meet your
                            unique needs. Our expertise encompasses the creation of diverse solutions, including
                            robust desktop applications, seamless Web APIs, intuitive mobile applications, and
                            efficient server software. Whether you require a user-friendly desktop tool, a powerful
                            web interface, a mobile app that connects with your audience, or a robust backend system,
                            we have the skills and experience to deliver cutting-edge solutions that will drive you success.
                        </p>
                    </Col>
                </Row>
                <hr />
                <Row className="py-5">
                    <Col lg={8} className="pt-4">
                        <h2 className="dtl-nobel">Website Designs</h2>
                        <p>
                            We specialize in creating captivating website designs that not only engage your audience
                            but also reflect your unique brand identity. Our designs are crafted with a keen focus
                            on user experience and aesthetics. Additionally, we ensure that all our websites are
                            mobile-friendly, guaranteeing a seamless and optimized experience on all devices. Whether
                            you need a stunning web presence, an e-commerce platform, or a responsive mobile app,
                            our design expertise ensures that your online presence stands out.
                        </p>
                    </Col>
                    <Col lg={4} className="text-center mt-3">
                        <img src={websiteIcon} alt="Website Designs" height={214} />
                    </Col>
                </Row>
                <hr />
                <Row className="py-5">
                    <Col lg={4} className="text-center mt-4">
                        <img src={businessIcon} alt="Business Services" height={214} />
                    </Col>
                    <Col lg={8} className="py-5">
                        <h2 className="dtl-nobel">Business Services</h2>
                        <p>
                            We provide custom-designed business services aimed at enhancing your overall workflow,
                            boosting employee productivity, and efficiently managing crucial business functions.
                            Uniquely designed tools for your business are carefully crafted to address your specific
                            needs, ensuring a streamlined and optimized operation. Whether it's enhancing your internal
                            processes, automating tasks, or facilitating data management, we are dedicated to empowering
                            your business to reach its full potential and achieve greater success.
                        </p>
                    </Col>
                </Row>
            </div>
            <div className="d-block d-md-none">
                <Row className="pt-5 pb-3">
                    <Col xs={12} className="text-center">
                        <img src={softwareIcon} alt="Software Development" height={128} />
                    </Col>
                    <Col xs={12} className="pt-5 text-center">
                        <h1 className="dtl-nobel">Software Development</h1>
                        <p className="fs-5">
                            We provide a wide range of tailored software development services, including desktop,
                            web, and mobile applications, as well as server software. Our experienced team can
                            deliver cutting-edge solutions to help you achieve success.
                        </p>
                    </Col>
                </Row>
                <hr />
                <Row className="py-3">
                    <Col xs={12} className="text-center">
                        <img src={websiteIcon} alt="Website Designs" height={128} />
                    </Col>
                    <Col xs={12} className="pt-5 text-center">
                        <h1 className="dtl-nobel">Website Designs</h1>
                        <p className="fs-5">
                            We specialize in creating captivating, mobile-friendly website designs that reflect
                            your brand identity and prioritize user experience and aesthetics. Whether you need
                            a stunning web presence, e-commerce platform, or a responsive mobile app, our designs
                            make your online presence stand out.
                        </p>
                    </Col>
                </Row>
                <hr />
                <Row className="py-3">
                    <Col xs={12} className="text-center mt-4">
                        <img src={businessIcon} alt="Business Services" height={128} />
                    </Col>
                    <Col xs={12} className="pt-5 text-center">
                        <h1 className="dtl-nobel">Business Services</h1>
                        <p className="fs-5">
                            We offer custom business services tailored to enhance workflow, boost productivity,
                            and optimize essential functions, with uniquely designed tools to streamline your
                            operation and empower your business for greater success.
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
    )

    return (<>
        <HeaderImage />
        <div className="bg-blue banner" />
        <Services />
    </>)
}

export default HomePage;