import { Route, Routes } from "react-router-dom";

import HomePage from "../pages/Home";
import PortfolioPage from "../pages/Portfolio";
import AboutPage from "../pages/About";
import ShadowCraftPage from "../pages/portfolio/ShadowCraft";
import EvilMinecraftPage from "../pages/portfolio/EvilMinecraft";
import CanaryModPage from "../pages/portfolio/CanaryMod";

const AppRouter = () => (
    <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/portfolio' element={<PortfolioPage />} />
        <Route path='/portfolio/evilminecraft' element={<EvilMinecraftPage />} />
        <Route path='/portfolio/canarymod' element={<CanaryModPage />} />
        <Route path='/portfolio/shadowcraft' element={<ShadowCraftPage />} />
    </Routes>
)

export default AppRouter;