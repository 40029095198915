//import { NavLink } from 'react-router-dom';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import fmLogo from '../assets/images/fm-logo.png'

import evmcLogo from '../assets/images/portfolio/evmc/logo.png';
import canaryLogo from '../assets/images/portfolio/canary/logo.png';
import scraftLogo from '../assets/images/portfolio/shadowcraft/logo.png';
import newEraLogo from '../assets/images/portfolio/newera/logo.png';
import mythosLogo from '../assets/images/portfolio/mythos/logo.png';
import thcLabzlogo from '../assets/images/portfolio/thclabz/logo.png';
import darknetLogo from '../assets/images/portfolio/darknet/logo.png';
import assetDashLogo from '../assets/images/portfolio/assetdash/logo.png';

import evmcIcon from '../assets/images/portfolio/evmc/icon.png';
import canaryIcon from '../assets/images/portfolio/canary/icon.png';
import scraftIcon from '../assets/images/portfolio/shadowcraft/icon.png';
import assetDashIcon from '../assets/images/portfolio/assetdash/flag.svg';

const PortfolioPage = () => {
    return (<>
        <Container>
            <Row className="featurette mt-5 mt-sm-0 mb-5 pt-5 pt-md-0 justify-content-center">
                <Col xs={10} md={7}>
                    <h1 className="featurette-heading fw-normal lh-1 dtl-nobel">
                        Our Portfolio
                        <br />
                        <span className="fs-2 text-muted-dark">
                            What have we been up to?
                        </span>
                    </h1>
                    <p className="lead">
                        We here at Fallen Moon have worked on a multitude of projects in the past and this portfolio will
                        show what we've accomplished and what we are working on next! Following each link will bring you
                        to a page where each item can display and explain what the project's goals were and more.
                    </p>
                </Col>
                <Col md={5} className="d-none d-sm-block text-center mt-5">
                    <Image className="featurette-image img-fluid mx-auto" src={fmLogo} width="400" height="400" />
                </Col>
            </Row>
        </Container>
        <div className="bg-blue banner" />
        <Container fluid className="g-0 portfolio-content">
            <a href="https://assetdash.com/marketcap" target="_blank" title="AssetDash - Marketcap" rel="noreferrer">
                <Row className="g-0 featurette" id="assetdash">
                    <Col md={5}>
                        <Image src={assetDashIcon} className="featurette-image portfolio-icon" />
                    </Col>
                    <Col md={7}>
                        <Image src={assetDashLogo} className="portfolio-logo" />
                    </Col>
                    <Col className="hover text-center d-none d-md-block">
                        <div className='center-content'>
                            <p className="about w-100 mx-auto fs-5">
                                AssetDash Market Cap is an application that teaches what the price of a security<br />
                                would be if it has the market cap of another security.
                                <br />
                                <br />
                                <Button className="feature-button ws-1 fs-4" id="assetdash-btn">Visit Application</Button>
                            </p>
                        </div>
                    </Col>
                </Row>
            </a>
            <a href="https://mythos.legendsmodding.com" target="_blank" title="Mythos" rel="noreferrer">
                <Row className="g-0 featurette" id="mythos">
                    <Col>
                        <Image src={mythosLogo} className="portfolio-logo" />
                    </Col>
                    <Col className="hover text-center">
                        <div className='center-content d-none d-md-block '>
                            <p className="about w-100 mx-auto fs-5">
                                Mythos is a mod distribution service created for the<br />
                                Minecraft Legends modding community Legends Modding
                                <br />
                                <br />
                                <Button className="feature-button ws-1 fs-4" id="mythos-btn">Visit Website</Button>
                            </p>
                        </div>
                    </Col>
                </Row>
            </a>
            <a href="https://www.newerawelding.com" target="_blank" title="New Era Welding" rel="noreferrer">
                <Row className="g-0 featurette" id="newera">
                    <Col>
                        <Image src={newEraLogo} className="portfolio-logo" />
                    </Col>
                    <Col className="hover text-center">
                        <div className='center-content d-none d-md-block '>
                            <p className="about w-100 mx-auto fs-5">
                                New Era Welding is an Orlando, FL based industrial welding company with a solid reputation<br />
                                of delivering some of the best tube and pipe solutions across many sectors.
                                <br />
                                <br />
                                <Button className="feature-button ws-1 fs-4" id="newera-btn">Visit Website</Button>
                            </p>
                        </div>
                    </Col>
                </Row>
            </a>
            <a href="https://darknetgames.xyz" target="_blank" title="Darknet Games" rel="noreferrer">
                <Row className="g-0 featurette" id="darknet">
                    <Col>
                        <Image src={darknetLogo} className="portfolio-logo" />
                    </Col>
                    <Col className="hover text-center">
                        <div className='center-content d-none d-md-block '>
                            <p className="about w-100 mx-auto fs-5">
                                Darknet Games is a Web3 Gaming platform utilizing an optional wagering<br />system while playing against other online players.
                                <br />
                                <br />
                                <Button className="feature-button ws-1 fs-4" id="darknet-btn">Visit Website</Button>
                            </p>
                        </div>
                    </Col>
                </Row>
            </a>
            <a href="https://thc-labz.xyz" target="_blank" title="THC Labz" rel="noreferrer">
                <Row className="g-0 featurette" id="thclabz">
                    <Col>
                        <Image src={thcLabzlogo} className="portfolio-logo" />
                    </Col>
                    <Col className="hover text-center">
                        <div className='center-content d-none d-md-block '>
                            <p className="about w-100 mx-auto fs-5">
                                THC Labz is a crypto community on the Solana blockchain,<br />using the $THC crypto token to power their projects.
                                <br />
                                <br />
                                <Button className="feature-button ws-1 fs-4" id="thclabz-btn">Visit Website</Button>
                            </p>
                        </div>
                    </Col>
                </Row>
            </a>
            <Row className="g-0 featurette" id="canary"> {/*as={NavLink} to="/portfolio/canarymod">*/}
                <Col md={5}>
                    <Image src={canaryIcon} className="featurette-image portfolio-icon" />
                </Col>
                <Col md={7}>
                    <Image src={canaryLogo} className="portfolio-logo" />
                </Col>
                <Col className="hover text-center d-none d-md-block">
                    <div className='center-content'>
                        <p className="about w-100 mx-auto fs-5">
                            CanaryMod was a Minecraft Server Manager that derived from<br />
                            another manager mod called hMod.
                            <br />
                            <br />
                            <Button className="feature-button ws-1 fs-4" id="canary-btn">Under Construction</Button>
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="g-0 featurette" id="evmc"> {/*as={NavLink} to="/portfolio/evilminecraft">*/}
                <Col md={7}>
                    <Image src={evmcLogo} className="portfolio-logo" />
                </Col>
                <Col md={5}>
                    <Image src={evmcIcon} className="featurette-image portfolio-icon" />
                </Col>
                <Col className="hover text-center d-none d-md-block">
                    <div className='center-content'>
                        <p className="about w-100 mx-auto fs-5">
                            Evil Minecraft was a Minecraft mod whose ambition was to<br />
                            change the entire way the game was played.
                            <br />
                            <br />
                            <Button className="feature-button ws-1 fs-4" id="evil-btn">Under Construction</Button>
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="g-0 featurette" id="scraft"> {/*as={NavLink} to="/portfolio/shadowcraft">*/}
                <Col md={7}>
                    <Image src={scraftLogo} className="portfolio-logo" />
                </Col>
                <Col md={5}>
                    <Image src={scraftIcon} className="featurette-image portfolio-icon" />
                </Col>
                <Col className="hover text-center d-none d-md-block">
                    <div className='center-content'>
                        <p className="about w-100 mx-auto fs-5">
                            Shadow-Craft was one of the first Minecraft Survival servers in 2011<br />
                            and continued on with community support for many years.
                            <br />
                            <br />
                            <Button className="feature-button ws-1 fs-4" id="scraft-btn">Under Construction</Button>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}

export default PortfolioPage;