import { Container, Row, Col, Image } from "react-bootstrap";

import fmLogo from '../assets/images/fm-logo.png';
import { EnvelopeAtFill, Github, Linkedin, Twitter } from "react-bootstrap-icons";

const AboutPage = () => {
    const contactUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfsH9O0ik2g4Osd-jmKmoMnH2RIsthpJOl2sa-n53oPa51npA/viewform';

    return <Container className="about-page">
        <Row className="featurette mt-5 mt-sm-0 mb-0 mb-md-5 pt-5 pt-md-0 justify-content-center" id="company">
            <Col xs={10} md={7}>
                <h1 className="featurette-heading fw-normal lh-1 dtl-nobel">
                    Fallen Moon
                    <br />
                    <span className="text-muted-dark fs-2">
                        Development Studio
                    </span>
                </h1>
                <p className="lead">
                    Founed in 2010, Fallen Moon is an LLC created by a group of developers, designers, and marketing experts filled with a
                    passion to create a unique experience for everyone using the web. The history we have together as a team helps to solidify
                    our abilities to serve the highest quality software, the cleanest designs, and the most stellar experience to an ever
                    growing internet.
                </p>
            </Col>
            <Col md={5} className="d-none d-sm-block text-center mt-5">
                <Image className="featurette-image img-fluid mx-auto" src={fmLogo} width="400" height="400" />
            </Col>
        </Row>
        <Row className="pb-0 py-5 justify-content-center gx-4 gx-md-5">
            <Col xs={10} lg={3} className="p-3 text-center rounded-3 shadow-lg mx-3 mb-5">
                <h1 className="display-6">Goals</h1>
                <p className="lead">
                    Our core mission is to develop innovative, high-quality software solutions that address our clients' unique needs.
                    We strive to stay at the forefront of technology trends, ensuring reliability, security, and a great user experience.
                    Our ultimate goal is to make a meaningful impact in the digital world.
                </p>
            </Col>
            <Col xs={10} lg={3} className="p-3 text-center rounded-3 shadow-lg mx-3 mb-5">
                <h1 className="display-6">Ambitions</h1>
                <p className="lead">
                    Our ambition is to provide high-quality software and websites at fair prices. We prioritize quality over quantity,
                    aiming to deliver exceptional value to our clients. Our commitment is to create bespoke solutions that cater to
                    individual needs, ensuring that affordability and excellence go hand in hand.
                </p>
            </Col>
            <Col xs={10} lg={3} className="p-3 text-center rounded-3 shadow-lg mx-3 mb-5">
                <h1 className="display-6">Visions</h1>
                <p className="lead">
                    Our visionary goal is to eliminate barriers and ensure universal web access. We are dedicated to empowering individuals,
                    businesses, and communities through our solutions, fostering growth, opportunity, and inclusivity for everyone in the
                    digital age.
                </p>
            </Col>
        </Row>
        <hr id="team" />
        <Row className="py-3 py-md-5 justify-content-center">
            <Col>
                <h1 className="dtl-nobel fs-0 text-center ws-1">Fallen Moon Team</h1>
            </Col>
        </Row>
        <Row className="pb-3 pb-md-5 justify-content-center">
            <Col md={4} className="text-center px-5">
                <Image className="rounded-circle shadow-lg my-3" width="140" height="140" src="https://static.thefallenmoon.com/images/shadow386.png" />
                <h2 className="fw-normal">
                    William Wands Jr.<br />
                    <small className="text-muted-dark">
                        Founder
                    </small>
                    <br />
                    <div className="text-blue text-center">
                        <a href="mailto:wwands@thefallenmoon.com" title="wwands@thefallenmoon.com" className="">
                            <EnvelopeAtFill />
                        </a>
                        &nbsp;
                        <a href="https://www.linkedin.com/in/shadow386" title="LinkedIn - William Wands Jr." className="" target="_blank" rel="noreferrer">
                            <Linkedin />
                        </a>
                        &nbsp;
                        <a href="https://x.com/shadow386" title="@Shadow386" className="" target="_blank" rel="noreferrer">
                            <Twitter />
                        </a>
                        &nbsp;
                        <a href="https://github.com/shadow386" title="GitHub" className="" target="_blank" rel="noreferrer">
                            <Github />
                        </a>
                    </div>
                </h2>
            </Col>
            <Col md={4} className="text-center px-5">
                <Image className="rounded-circle shadow-lg my-3" width="140" height="140" src="https://cdn.discordapp.com/avatars/146328882461540353/e6d31c6d60f909bffb27d4c624cb22a2.webp" />
                <h2 className="fw-normal">
                    Chris Beidler<br />
                    <small className="text-muted-dark">
                        Chief Technical Officer
                    </small>
                    <br />
                    <div className="text-blue text-center">
                        <a href="mailto:cbeidler@thefallenmoon.com" title="cbeidler@thefallenmoon.com" className="">
                            <EnvelopeAtFill />
                        </a>
                        &nbsp;
                        <a href="https://x.com/xaphobia" title="@XaPhobia" className="" target="_blank" rel="noreferrer">
                            <Twitter />
                        </a>
                    </div>
                </h2>
            </Col>
            <Col md={4} className="text-center px-5">
                <div className="rounded-circle shadow-lg my-3 mx-auto" style={{ height: 140, width: 140 }}>
                    <Image className="p-3" width="140" height="140" src={fmLogo} />
                </div>
                <h2 className="fw-normal">
                    Samuel Lane<br />
                    <small className="text-muted-dark">
                        Chief Financial Officer
                    </small>
                    <br />
                    <div className="text-blue text-center">
                        <a href="mailto:slane@thefallenmoon.com" title="slane@thefallenmoon.com" className="">
                            <EnvelopeAtFill />
                        </a>
                    </div>
                </h2>
            </Col>
        </Row>
        <hr id="contact" />
        <Row className="pt-3 pt-md-5 justify-content-center">
            <Col>
                <h1 className="dtl-nobel fs-0 text-center ws-1">Contact Us</h1>
            </Col>
        </Row>
        <Row className="py-5 justify-content-center d-none d-md-block">
            <Col>
                <iframe
                    src={`${contactUrl}?embedded=true`}
                    width="1280"
                    height="800"
                    title="Contact Us">
                    Loading…
                </iframe>
            </Col>
        </Row>
        <Row className="py-3 py-md-5 justify-content-center d-block d-md-none text-center">
            <Col>
                <a href={contactUrl} title="Contact Us" rel="noreferrer" target="_blank" className="btn btn-primary btn-lg mb-3">
                    Contact Form
                </a>
            </Col>
        </Row>
    </Container>
}

export default AboutPage;