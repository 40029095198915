import { BrowserRouter as Router } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./assets/styles/app.min.css";

import { Header, Footer } from "./components/Layout";
import AppRouter from "./components/AppRouter";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Container fluid className="g-0" data-bs-theme="dark">
        <div className="content">
          <AppRouter />
        </div>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
