import { Container, Row, Col, Image } from 'react-bootstrap';
import icon from '../../assets/images/portfolio/shadowcraft/icon.png';
import logo from '../../assets/images/portfolio/shadowcraft/logo.png';

const ShadowCraftPage = () => {
    return (
        <Container fluid>
            <Row className='shadowcraft-content text-light'>
                <Col className="mt-3">
                    <span className="portfolio-splash">
                        <Image src={icon} className='shadowcraft-icon' />
                        <Image src={logo} className='shadowcraft-logo' />
                    </span>
                    {
                        /*
                            TODO: Write a page that explains who we are, what we want to do, what our future is like, and how we plan to achieve it.
                            Mention some of our past accomplishments (CanaryMod, EvilMC, Shadow-Craft), and how it affected our growth.
                        */
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default ShadowCraftPage;