import { useState } from "react";
import { Container, Image, Navbar, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import fmlogo from '../assets/images/fm-logo.png';

const Navigation = () => {
    const [expanded, setExpanded] = useState(false);
    const logo = () => (
        <div className="fallen-logo bg-white">
            <Link to='/'>
                <Image src={fmlogo} height="64"></Image>
            </Link>
        </div>
    );

    return (
        <Navbar className="primary-navbar" fixed="top" bg="light" expand="lg" onToggle={() => setExpanded(!expanded)} expanded={expanded}>
            <Container className="g-0">
                {logo()}
                <Row className="justify-content-between w-100 d-none d-md-flex">
                    <Col md={6} className="text-start">
                        <Navbar.Brand as={Link} className="dtl-nobel fs-4" tabIndex={0} to='/'>Fallen Moon</Navbar.Brand>
                    </Col>
                    <Col md={6} className="text-end py-2">
                        <Link className="text-dark px-3" tabIndex={1} to="/portfolio">Portfolio</Link>
                        <HashLink
                            className="text-dark px-3"
                            tabIndex={2}
                            to="/about#company"
                            scroll={(el) => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                        >
                            About
                        </HashLink>
                        <HashLink
                            className="text-dark px-3"
                            tabIndex={3}
                            to="/about#team"
                            scroll={(el) => {
                                const yOffset = -100;
                                const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                            }}
                        >
                            The Team
                        </HashLink>
                        <HashLink
                            className="text-dark px-3"
                            tabIndex={4}
                            to="/about#contact"
                            scroll={(el) => {
                                const yOffset = -100;
                                const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
                                window.scrollTo({ top: y, behavior: 'smooth' });
                            }}
                        >
                            Contact Us
                        </HashLink>
                    </Col>
                </Row>
                <Row className="justify-content-between w-100 d-flex d-md-none">
                    <Navbar.Brand as={Link} className="dtl-nobel fs-4 brand-text" tabIndex={0} to='/'>Fallen Moon</Navbar.Brand>
                    <Col xs={6} className="text-start">
                        <Link className="text-light px-3" tabIndex={1} to="/portfolio">Portfolio</Link>
                    </Col>
                    <Col xs={6} className="text-end">
                        <Link className="text-light px-3" tabIndex={2} to="/about">About</Link>
                    </Col>
                </Row>
            </Container>
            <div className="bg-blue page-header" />
        </Navbar>
    )
}

export default Navigation;